import React, { Component } from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import aboutImg from "../images/default.jpg"
import Link from "gatsby"
import SidebarComponent from "../components/SidebarComponent"

class About extends Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <div>
        <Helmet title={siteTitle} />
        <Layout>
          <section className="about-us pad-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <SidebarComponent />
                </div>

                <div className="col-lg-8">
                  <div className="image-container">
                    <img src={aboutImg} alt="about" />
                  </div>
                  <h3>北海道森林療法研究会</h3>
                  <h4>1.当会の趣旨</h4>
                  <p>
                    森林浴は野外レクリエーション活動の１つとして広く社会に認知されています。健康状態の改善、維持増進のために森林環境ないで運動、リハビリテーション、リラクゼーションなどを行うことを森林療法と呼んでます。これまでの研究から森林環境が心理状態、自律神経バランス、免疫機能、認知機能、ストレス、血圧や血糖の改善効果があるこが確認されています。しかし、科学的、医学的効果の検証はまだ十分とはいえません。
                    〇北海道の森林をフィールドとして森林療法の科学的、医学的根拠を明らかにすることを目的として活動しています。
                    〇森林療法の有効性を検証し、その結果を多くの人々の健康保持増進に役立てたいと考えプログラム開発を行っています。
                    〇森林療法の普及を通して、多くの方々に社会貢献活動の場を提供したいと考えています。
                  </p>
                  <h4>2.NPO法人申請に至るまでの経緯</h4>
                  <p>
                    1992年、代表の住友がかかわった研究において森林揮発性物質（フィトンチッド）であるαピネンに交感神経活性抑制・副交感神経賦活作用があり、ストレスによって低下した抗体産生能を回復させる効果があることが示されました。
                    2004年、住友が赴任した北海道北部の地域は、高血圧患者の割合が高く脳出血発症率が全国平均よりも10％も高い地域でした。住民の健康を守るという観点から高血圧のコントロールが重要と考え、森林揮発性物質の自律神経バランス改善効果に注目し、2005年8月、“森林ウォーキングが血圧に与える影響”について20名のボランティアに参加してもらい検証をおこないました。10名ずつ2群に分け、街頭歩行と森林ウォーキングのクロス試験を行いました。
                    森林ウォーキング群では、街頭歩行群に比べ、血圧が適度に下がる現象が確認されました。心拍変動解析の結果では交感神経活性が抑制され、ストレスホルモンである血清コルチゾールの抑制も確認されました。この結果を踏まえて住民の生活習慣病予防対策として森林ウォーキングが住民対象の健康づくりイベントとして病院・保健福祉課の行事として毎月実施されるようになりました。町民の有志により森林療法の効果を検証し普及をはかることを目的とした中頓別森林療法研究会が発足、その後、高血圧はじめ生活習慣病の代替療法、生活習慣改善の一つとして森林療法を広く社会に普及する事を目的に2006年8月NPO法人化へ向けた申請をおこない、11月にＮＰＯ法人が成立しました。
                    北海道のローカルな活動でしたが、“医学的検証”と“健康づくりのフィールドワーク”を車の両輪として行うことで、2012年4月以降、道内各地（東川町、旭川市、美瑛町、芦別市、黒松内町など）、道外（島根県飯南町、長野県飯山市、仙台市など）へと活動範囲が徐々に広がりました。北海道はじめ全国の地域の健康づくりと地域づくりに貢献し、その成果を広く世界へも発信し多くの人々の健康維持増進に役立てたいと考えています。
                  </p>
                  <h4>これから</h4>

                  <p>
                    令和2年6月時点で私たちの活動は、大きく➀フィールドワークと②機能性アロマ開発に分かれます。
                    　新型コロナ流行により日常生活の在り方が変わろうとしています。新型コロナ流行を機に働き方はテレワークにシフトしましたが、新しい働き方は新しいストレスを生んでいます。ポストコロナ時代においてストレス、メンタル、健康の管理が難しくなったかも知れません。これらを良好な状態に保つ方法として森林療法を利用していただければ野外活動を通して自然に無理なく適度な運動ができ、家族や同僚とコミュニケーションが取れ、ストレスは減り、メンタルはポジティブに、無理せず自然に健康維持に役立つと思います。
                    　もり、近隣の森へ出かけられない時には機能性アロマを使い、音楽を聴きながら気持ちを落ち着ける時間を作っていただきたいと思います。
                    　森林の持つ多様性を人の健康につなげるためにいろいろな森林療法のオプションを提案したいと考えています。
                  </p>
                  <br />
                  <p>令和2年6月1日　　北海道森林療法研究会　代表　住友和弘</p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    )
  }
}

export default About

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
